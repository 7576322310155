<template>
  <div class="wrapper">
    <b-card  class="wrapper">
      <b-container>
        <b-row>
          <b-col>
            <p class="TextBlack32">
              {{ $t("answerandquestion") }}
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col
            sm="12"
            md="12"
            v-for="(item, index) in QuestionList"
            :key="index"
          >
            <b-card no-body class="mb-1 famouscard1">
              <b-card-header
                header-tag="header"
                class="p-1 famouscard1"
                role="tab"
              >
                <b-button
                  block
                  v-b-toggle="'accordion-' + item.id"
                  variant="white"
                  class="text"
                  ><p
                    class="school15"
                    style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;position:absolute"
                  >
                    {{ item.question }}
                  </p>
                  <b-icon
                    class="IconStyle"
                    :icon="
                      'accordion-' + item.id ? 'chevron-down' : 'chevron-up'
                    "
                  ></b-icon
                ></b-button>
              </b-card-header>
              <b-collapse
                :id="'accordion-' + item.id"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-card-text>
                    <p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                    >
                      {{ item.answer }}
                    </p>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </b-col>
        </b-row>
        <!-- <b-row>
          <b-col>
            <b-card no-body class="mb-1 famouscard1">
              <b-card-header
                header-tag="header"
                class="p-1 famouscard1"
                role="tab"
              >
                <b-button
                  block
                  @click="ischange = !ischange"
                  variant="white"
                  class="text"
                  ><p
                    class="school15"
                    style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;position:absolute"
                  >
                    {{ $t("question_1") }}
                  </p>
                  <b-icon
                    class="IconStyle"
                    :icon="ischange ? 'chevron-up' : 'chevron-down'"
                  ></b-icon
                ></b-button>
              </b-card-header>
              <b-collapse
                :visible="ischange"
                id="accordion-1"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-card-text>
                    <p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                    >
                      {{ $t("question_1_info") }}
                    </p>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-card no-body class="mb-1 famouscard1">
              <b-card-header
                header-tag="header"
                class="p-1 famouscard1"
                role="tab"
              >
                <b-button
                  block
                  @click="ischange2 = !ischange2"
                  variant="white"
                  class="text"
                  ><p
                    class="school15"
                    style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;position:absolute"
                  >
                    {{ $t("question_2") }}
                  </p>
                  <b-icon
                    class="IconStyle"
                    :icon="ischange2 ? 'chevron-up' : 'chevron-down'"
                  ></b-icon
                ></b-button>
              </b-card-header>
              <b-collapse
                :visible="ischange2"
                id="accordion-2"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-card-text>
                    <p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                    >
                      {{ $t("question_2_info") }}
                    </p>
                    <p
                      class="school15 ml-3"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                    >
                      {{ $t("question_2_info_1") }}
                    </p>
                    <p
                      class="school15 ml-3"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                    >
                      {{ $t("question_2_info_2") }}
                    </p>
                    <p
                      class="school15 ml-3"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                    >
                      {{ $t("question_2_info_3") }}
                    </p>
                    <p
                      class="school15 ml-3"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                    >
                      {{ $t("question_2_info_4") }}
                    </p>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-card no-body class="mb-1 famouscard1">
              <b-card-header
                header-tag="header"
                class="p-1 famouscard1"
                role="tab"
              >
                <b-button
                  block
                  @click="ischange3 = !ischange3"
                  variant="white"
                  class="text"
                  ><p
                    class="school15"
                    style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;position:absolute"
                  >
                    {{ $t("question_3") }}
                  </p>
                  <b-icon
                    class="IconStyle"
                    :icon="ischange3 ? 'chevron-up' : 'chevron-down'"
                  ></b-icon
                ></b-button>
              </b-card-header>
              <b-collapse
                :visible="ischange3"
                id="accordion-3"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-card-text>
                    <p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                    >
                      {{ $t("question_3_info_1") }}
                    </p>
                    <p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                    >
                      {{ $t("question_3_info_2") }}
                    </p>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-card no-body class="mb-1 famouscard1">
              <b-card-header
                header-tag="header"
                class="p-1 famouscard1"
                role="tab"
              >
                <b-button
                  block
                  @click="ischange4 = !ischange4"
                  variant="white"
                  class="text"
                  ><p
                    class="school15"
                    style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;position:absolute"
                  >
                    {{ $t("question_4") }}
                  </p>
                  <b-icon
                    class="IconStyle"
                    :icon="ischange4 ? 'chevron-up' : 'chevron-down'"
                  ></b-icon
                ></b-button>
              </b-card-header>
              <b-collapse
                :visible="ischange4"
                id="accordion-4"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-card-text>
                    <p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                    >
                      {{ $t("question_4_info_1") }}
                    </p>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-card no-body class="mb-1 famouscard1">
              <b-card-header
                header-tag="header"
                class="p-1 famouscard1"
                role="tab"
              >
                <b-button
                  block
                  @click="ischange5 = !ischange5"
                  variant="white"
                  class="text"
                  ><p
                    class="school15"
                    style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;position:absolute"
                  >
                    {{ $t("question_5") }}
                  </p>
                  <b-icon
                    class="IconStyle"
                    :icon="ischange5 ? 'chevron-up' : 'chevron-down'"
                  ></b-icon
                ></b-button>
              </b-card-header>
              <b-collapse
                :visible="ischange5"
                id="accordion-5"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-card-text>
                    <p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                    >
                      {{ $t("question_5_info_1") }}
                    </p>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-card no-body class="mb-1 famouscard1">
              <b-card-header
                header-tag="header"
                class="p-1 famouscard1"
                role="tab"
              >
                <b-button
                  block
                  @click="ischange6 = !ischange6"
                  variant="white"
                  class="text"
                  ><p
                    class="school15"
                    style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;position:absolute"
                  >
                    {{ $t("question_6") }}
                  </p>
                  <b-icon
                    class="IconStyle"
                    :icon="ischange6 ? 'chevron-up' : 'chevron-down'"
                  ></b-icon
                ></b-button>
              </b-card-header>
              <b-collapse
                :visible="ischange6"
                id="accordion-6"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-card-text>
                    <p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                    >
                      {{ $t("question_6_info") }}
                    </p>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-card no-body class="mb-1 famouscard1">
              <b-card-header
                header-tag="header"
                class="p-1 famouscard1"
                role="tab"
              >
                <b-button
                  block
                  @click="ischange7 = !ischange7"
                  variant="white"
                  class="text"
                  ><p
                    class="school15"
                    style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;position:absolute"
                  >
                    {{ $t("question_7") }}
                  </p>
                  <b-icon
                    class="IconStyle"
                    :icon="ischange7 ? 'chevron-up' : 'chevron-down'"
                  ></b-icon
                ></b-button>
              </b-card-header>
              <b-collapse
                :visible="ischange7"
                id="accordion-7"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-card-text>
                    <p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                    >
                      {{ $t("question_7_info_1") }}
                    </p>
                    <p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                    >
                      {{ $t("question_7_info_2") }}
                    </p>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-card no-body class="mb-1 famouscard1">
              <b-card-header
                header-tag="header"
                class="p-1 famouscard1"
                role="tab"
              >
                <b-button
                  block
                  @click="ischange8 = !ischange8"
                  variant="white"
                  class="text"
                  ><p
                    class="school15"
                    style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;position:absolute"
                  >
                    {{ $t("question_8") }}
                  </p>
                  <b-icon
                    class="IconStyle"
                    :icon="ischange8 ? 'chevron-up' : 'chevron-down'"
                  ></b-icon
                ></b-button>
              </b-card-header>
              <b-collapse
                :visible="ischange8"
                id="accordion-8"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-card-text>
                    <p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                    >
                      {{ $t("question_8_info_1") }}
                    </p>
                    <p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                    >
                      {{ $t("question_8_info_2") }}
                    </p>
                    <p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                    >
                      {{ $t("question_8_info_3") }}
                    </p>
                    <p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                    >
                      {{ $t("question_8_info_4") }}
                    </p>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-card no-body class="mb-1 famouscard1">
              <b-card-header
                header-tag="header"
                class="p-1 famouscard1"
                role="tab"
              >
                <b-button
                  block
                  @click="ischange9 = !ischange9"
                  variant="white"
                  class="text"
                  ><p
                    class="school15"
                    style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;position:absolute"
                  >
                    {{ $t("question_9") }}
                  </p>
                  <b-icon
                    class="IconStyle"
                    :icon="ischange9 ? 'chevron-up' : 'chevron-down'"
                  ></b-icon
                ></b-button>
              </b-card-header>
              <b-collapse
                :visible="ischange9"
                id="accordion-9"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-card-text>
                    <p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                    >
                      {{ $t("question_9_info") }}
                    </p>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-card no-body class="mb-1 famouscard1">
              <b-card-header
                header-tag="header"
                class="p-1 famouscard1"
                role="tab"
              >
                <b-button
                  block
                  @click="ischange10 = !ischange10"
                  variant="white"
                  class="text"
                  ><p
                    class="school15"
                    style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;position:absolute"
                  >
                    {{ $t("question_10") }}
                  </p>
                  <b-icon
                    class="IconStyle"
                    :icon="ischange10 ? 'chevron-up' : 'chevron-down'"
                  ></b-icon
                ></b-button>
              </b-card-header>
              <b-collapse
                :visible="ischange10"
                id="accordion-10"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-card-text>
                    <p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                    >
                      {{ $t("question_10_info") }}
                    </p>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-card no-body class="mb-1 famouscard1">
              <b-card-header
                header-tag="header"
                class="p-1 famouscard1"
                role="tab"
              >
                <b-button
                  block
                  @click="ischange11 = !ischange11"
                  variant="white"
                  class="text"
                  ><p
                    class="school15"
                    style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;position:absolute"
                  >
                    {{ $t("question_11") }}
                  </p>
                  <b-icon
                    class="IconStyle"
                    :icon="ischange11 ? 'chevron-up' : 'chevron-down'"
                  ></b-icon
                ></b-button>
              </b-card-header>
              <b-collapse
                :visible="ischange11"
                id="accordion-11"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-card-text>
                    <p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                    >
                      {{ $t("question_11_info_1") }}
                    </p>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-card no-body class="mb-1 famouscard1">
              <b-card-header
                header-tag="header"
                class="p-1 famouscard1"
                role="tab"
              >
                <b-button
                  block
                  @click="ischange12 = !ischange12"
                  variant="white"
                  class="text"
                  ><p
                    class="school15"
                    style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;position:absolute"
                  >
                    {{ $t("question_12") }}
                  </p>
                  <b-icon
                    class="IconStyle"
                    :icon="ischange12 ? 'chevron-up' : 'chevron-down'"
                  ></b-icon
                ></b-button>
              </b-card-header>
              <b-collapse
                :visible="ischange12"
                id="accordion-12"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-card-text>
                    <p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                    >
                      {{ $t("question_12_info_1") }}
                    </p>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-card no-body class="mb-1 famouscard1">
              <b-card-header
                header-tag="header"
                class="p-1 famouscard1"
                role="tab"
              >
                <b-button
                  block
                  @click="ischange13 = !ischange13"
                  variant="white"
                  class="text"
                  ><p
                    class="school15"
                    style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;position:absolute"
                  >
                    {{ $t("question_13") }}
                  </p>
                  <b-icon
                    class="IconStyle"
                    :icon="ischange13 ? 'chevron-up' : 'chevron-down'"
                  ></b-icon
                ></b-button>
              </b-card-header>
              <b-collapse
                :visible="ischange13"
                id="accordion-13"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-card-text>
                    <p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                    >
                      {{ $t("question_13_info_1") }}
                    </p>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-card no-body class="mb-1 famouscard1">
              <b-card-header
                header-tag="header"
                class="p-1 famouscard1"
                role="tab"
              >
                <b-button
                  block
                  @click="ischange14 = !ischange14"
                  variant="white"
                  class="text"
                  ><p
                    class="school15"
                    style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;position:absolute"
                  >
                    {{ $t("question_14") }}
                  </p>
                  <b-icon
                    class="IconStyle"
                    :icon="ischange14 ? 'chevron-up' : 'chevron-down'"
                  ></b-icon
                ></b-button>
              </b-card-header>
              <b-collapse
                :visible="ischange14"
                id="accordion-14"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-card-text>
                    <p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                    >
                      {{ $t("question_14_info") }}
                    </p>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-card no-body class="mb-1 famouscard1">
              <b-card-header
                header-tag="header"
                class="p-1 famouscard1"
                role="tab"
              >
                <b-button
                  block
                  @click="ischange15 = !ischange15"
                  variant="white"
                  class="text"
                  ><p
                    class="school15"
                    style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;position:absolute"
                  >
                    {{ $t("question_15_1") }}
                  </p>
                  <b-icon
                    class="IconStyle"
                    :icon="ischange15 ? 'chevron-up' : 'chevron-down'"
                  ></b-icon
                ></b-button>
              </b-card-header>
              <b-collapse
                :visible="ischange15"
                id="accordion-15"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-card-text>
                    <p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                    >
                      {{ $t("question_15_info_1") }}
                    </p>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-card no-body class="mb-1 famouscard1">
              <b-card-header
                header-tag="header"
                class="p-1 famouscard1"
                role="tab"
              >
                <b-button
                  block
                  @click="ischange16 = !ischange16"
                  variant="white"
                  class="text"
                  ><p
                    class="school15"
                    style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;position:absolute"
                  >
                    {{ $t("question_16") }}
                  </p>
                  <b-icon
                    class="IconStyle"
                    :icon="ischange16 ? 'chevron-up' : 'chevron-down'"
                  ></b-icon
                ></b-button>
              </b-card-header>
              <b-collapse
                :visible="ischange16"
                id="accordion-16"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-card-text>
                    <p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                    >
                      {{ $t("question_16_info_1") }}
                    </p>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-card no-body class="mb-1 famouscard1">
              <b-card-header
                header-tag="header"
                class="p-1 famouscard1"
                role="tab"
              >
                <b-button
                  block
                  @click="ischange17 = !ischange17"
                  variant="white"
                  class="text"
                  ><p
                    class="school15"
                    style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;position:absolute"
                  >
                    {{ $t("question_17_1") }}
                  </p>
                  <b-icon
                    class="IconStyle"
                    :icon="ischange17 ? 'chevron-up' : 'chevron-down'"
                  ></b-icon
                ></b-button>
              </b-card-header>
              <b-collapse
                :visible="ischange17"
                id="accordion-17"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-card-text>
                    <p
                      class="school15"
                      style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;"
                    >
                      {{ $t("question_17_info_1") }}
                    </p>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-card no-body class="mb-1 famouscard1">
              <b-card-header
                header-tag="header"
                class="p-1 famouscard1"
                role="tab"
              >
                <b-button
                  block
                  @click="ischange18 = !ischange18"
                  variant="white"
                  class="text"
                  ><p
                    class="school15"
                    style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;position:absolute"
                  >
                    {{ $t("question_18") }}
                  </p>
                  <b-icon
                    class="IconStyle"
                    :icon="ischange18 ? 'chevron-up' : 'chevron-down'"
                  ></b-icon
                ></b-button>
              </b-card-header>
              <b-collapse
                :visible="ischange18"
                id="accordion-18"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-card-text>
                    <p
                      class="school15"
                      style="display:block; margin-top:auto; margin-bottom:auto;text-align:left"
                    >
                      {{ $t("question_18_info") }}
                      <br />{{ $t("question_18_info_1") }}
                    </p>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-card no-body class="mb-1 famouscard1">
              <b-card-header
                header-tag="header"
                class="p-1 famouscard1"
                role="tab"
              >
                <b-button
                  block
                  @click="ischange19 = !ischange19"
                  variant="white"
                  class="text"
                  ><p
                    class="school15"
                    style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;position:absolute"
                  >
                    {{ $t("question_19") }}
                  </p>
                  <b-icon
                    class="IconStyle"
                    :icon="ischange19 ? 'chevron-up' : 'chevron-down'"
                  ></b-icon
                ></b-button>
              </b-card-header>
              <b-collapse
                :visible="ischange19"
                id="accordion-19"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-card-text>
                    <p
                      class="school15"
                      style="display:block; margin-top:auto; margin-bottom:auto;text-align:left"
                    >
                      {{ $t("question_19_info") }}
                    </p>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <b-card no-body class="mb-1 famouscard1">
              <b-card-header
                header-tag="header"
                class="p-1 famouscard1"
                role="tab"
              >
                <b-button
                  block
                  @click="ischange20 = !ischange20"
                  variant="white"
                  class="text"
                  ><p
                    class="school15"
                    style="text-align:left;display:block; margin-top:auto; margin-bottom:auto;position:absolute"
                  >
                    {{ $t("question_20") }}
                  </p>
                  <b-icon
                    class="IconStyle"
                    :icon="ischange20 ? 'chevron-up' : 'chevron-down'"
                  ></b-icon
                ></b-button>
              </b-card-header>
              <b-collapse
                :visible="ischange20"
                id="accordion-20"
                accordion="my-accordion"
                role="tabpanel"
              >
                <b-card-body>
                  <b-card-text>
                    <p
                      class="school15"
                      style="display:block; margin-top:auto; margin-bottom:auto;text-align:left"
                    >
                      {{ $t("question_20_info") }}
                    </p>
                  </b-card-text>
                </b-card-body>
              </b-collapse>
            </b-card>
          </b-col>
        </b-row> -->
      </b-container>
    </b-card>
  </div>
</template>
<script>
import QuestionService from "@/services/Question.service";
export default {
  data() {
    return {
      ischange: false,
      ischange2: false,
      ischange3: false,
      ischange4: false,
      ischange5: false,
      ischange6: false,
      ischange7: false,
      ischange8: false,
      ischange9: false,
      ischange10: false,
      ischange11: false,
      ischange12: false,
      ischange13: false,
      ischange14: false,
      ischange15: false,
      ischange16: false,
      ischange17: false,
      ischange18: false,
      ischange19: false,
      ischange20: false,

      filter: {
        Search: "",
        SortColumn: "",
        OrderType: "asc",
        PageNumber: 1,
        PageLimit: 1000,
        lang: "",
      },
      QuestionList: [],
    };
  },
  created() {
    this.filter.lang = localStorage.getItem("lang") || "uz_cyrl";
    this.Refresh();
  },
  methods: {
    Refresh() {
      QuestionService.GetList(
        this.filter.Search,
        this.filter.SortColumn,
        this.filter.OrderType,
        this.filter.PageNumber,
        this.filter.PageLimit,
        this.filter.lang
      ).then((res) => {
        this.QuestionList = res.data.rows;
      });
    },
  },
};
</script>
<style></style>
